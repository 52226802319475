import Vue from 'vue';
import { importRunTask } from '../../assets/js/utilities/dynamicImports';

interface IWatchRenderSizingPayload {
	refKey: string;
	callback: Function;
	successCallback?: Function;
	failCallback?: Function;
	minHeight?: number;
	minWidth?: number;
	timeout?: number;
	expirationDate?: number; // This property is only used internally on recurrent run
	watcherKey?: string; // Optional watcher key. If undefined, render sizing mixin will use the refKey as watcherKey
}

export interface IRenderSizingMixin {
	renderSizing: {
		renderingFinished: boolean;
		renderSizingDefaultTimeout: number;
	};
	uid: string;

	watchRenderSizing(payload: IWatchRenderSizingPayload): void;
}

export const RenderSizingMixin = Vue.extend({
	data() {
		return {
			renderSizing: {
				renderingFinished: {} as { [key: string]: boolean },
				renderWatchers: {} as { [key: string]: number },
				isSSR: true,
				renderSizingDefaultTimeout: 500,
				lastMeasuredDimensions: {} as {
					[key: string]: {
						width?: number;
						height?: number;
					};
				},
				watchProgressSettings: {} as { [key: string]: IWatchRenderSizingPayload },
			},
		};
	},
	beforeDestroy() {
		this._cleanupRenderSizingChecks();
	},
	created() {
		this.renderSizing.isSSR = process.server;
	},
	methods: {
		watchRenderSizing(payload: IWatchRenderSizingPayload) {
			if (this.renderSizing.isSSR || this.renderSizing.renderingFinished[payload.refKey]) return;

			const watcherKey = payload.watcherKey ?? payload.refKey;

			if (!(watcherKey in this.renderSizing.watchProgressSettings)) {
				this.renderSizing.watchProgressSettings[watcherKey] = payload;
				this._ensureTimeouts(watcherKey);
			}

			importRunTask().then(({ runTask }) => {
				runTask(() => {
					if (this._watchedElementFinishedGrowing(payload.refKey, watcherKey)) {
						this._executeSuccessCallback(watcherKey);
						return;
					}

					if (this._checkStartNextIteration(watcherKey)) return;

					this._handleFailedWatch(watcherKey);
				});
			});
		},
		_executeSuccessCallback(watcherKey: string): void {
			if (!(watcherKey in this.renderSizing.watchProgressSettings)) return;

			if (
				typeof this.renderSizing?.watchProgressSettings?.[watcherKey]?.successCallback ===
				'function'
			) {
				this.renderSizing.watchProgressSettings?.[watcherKey]?.successCallback?.();
			} else if (this.renderSizing.watchProgressSettings[watcherKey]?.callback) {
				this.renderSizing.watchProgressSettings[watcherKey]?.callback();
			}
		},
		_checkStartNextIteration(watcherKey: string): boolean {
			// If expiration date is given and reached - return no next iteration needed
			if (
				this.renderSizing.watchProgressSettings?.[watcherKey]?.expirationDate &&
				Date.now() > (this.renderSizing.watchProgressSettings[watcherKey]?.expirationDate ?? 0)
			) {
				return false;
			}

			// If reached this point - selector not yet finished to render. Check again
			if (this.renderSizing.watchProgressSettings?.[watcherKey]?.refKey) {
				this.renderSizing.renderWatchers[watcherKey] = window.requestAnimationFrame(() => {
					importRunTask().then(({ runTask }) => {
						runTask(() => {
							this.watchRenderSizing(this.renderSizing.watchProgressSettings[watcherKey]);
						});
					});
				});

				return true;
			}

			return false;
		},
		_handleFailedWatch(watcherKey: string) {
			if (watcherKey in this.renderSizing.watchProgressSettings) {
				importRunTask().then(({ runTask }) => {
					runTask(() => {
						if (this.renderSizing.watchProgressSettings?.[watcherKey]?.failCallback) {
							this.renderSizing.watchProgressSettings[watcherKey]?.failCallback?.();
						} else if (this.renderSizing.watchProgressSettings[watcherKey]?.callback) {
							this.renderSizing.watchProgressSettings[watcherKey]?.callback();
						}
					});
				});
			}

			this._cleanupRenderSizingChecks(watcherKey);
		},
		_ensureTimeouts(refKey: string): void {
			if (!this.renderSizing.watchProgressSettings[refKey]?.timeout) {
				this.renderSizing.watchProgressSettings[refKey].timeout =
					this.renderSizing.renderSizingDefaultTimeout;
			}

			if (!this.renderSizing.watchProgressSettings[refKey]?.expirationDate) {
				this.renderSizing.watchProgressSettings[refKey].expirationDate =
					Date.now() + (this.renderSizing.watchProgressSettings?.[refKey]?.timeout ?? 500);
			}
		},
		_watchedElementFinishedGrowing(refKeyToWatch: string, watcherKey: string): boolean {
			const watchingEl = this._getWatchingElement(refKeyToWatch);

			if (!watchingEl || !(refKeyToWatch in this.renderSizing?.watchProgressSettings)) {
				return false;
			}

			const isIntersectingPlaceholder = this._isWatchingElementIntersectingPlaceholder(watchingEl);

			// Check if element has defined minimum Dimensions
			if (
				!isIntersectingPlaceholder &&
				watchingEl.clientHeight >
					(this.renderSizing.watchProgressSettings[watcherKey]?.minHeight ?? 0) &&
				watchingEl.clientWidth >
					(this.renderSizing.watchProgressSettings[watcherKey]?.minWidth ?? 0)
			) {
				// Also check if element stopped growing
				if (
					watchingEl.clientHeight ===
						this.renderSizing.lastMeasuredDimensions[watcherKey]?.height &&
					watchingEl.clientWidth === this.renderSizing.lastMeasuredDimensions[watcherKey]?.width
				) {
					this._cleanupRenderSizingChecks(watcherKey);
					return true;
				} else {
					// ... otherwise store current measured dimensions and check in next rendering round
					this.renderSizing.lastMeasuredDimensions[watcherKey] = {
						height: watchingEl.clientHeight,
						width: watchingEl.clientWidth,
					};
				}
			}

			return false;
		},
		_isWatchingElementIntersectingPlaceholder(watchingEl: Element): boolean {
			// IntersectingPlaceholder element has a fixed height of 2000px. Check for this and other indicatives to find out
			// if the watching element is a IntersectingPlaceholder
			return (
				watchingEl.clientHeight === 2000 &&
				(watchingEl.innerHTML === '' ||
					(Object.keys(watchingEl.childNodes).length === 1 &&
						(watchingEl.firstChild as Element).innerHTML === ''))
			);
		},
		_getWatchingElement(refKeyToWatch: string): Element | undefined {
			const refToWatch: Vue | Element | (Vue | Element)[] | undefined =
				refKeyToWatch === 'self' && this.$el ? this.$el : this.$refs[refKeyToWatch];

			if (refToWatch) {
				const watchingRef = Array.isArray(refToWatch) ? refToWatch[0] : refToWatch;
				return typeof watchingRef === 'object' && '$el' in watchingRef
					? watchingRef.$el
					: watchingRef;
			}

			return undefined;
		},
		_cleanupRenderSizingChecks(watcherKey: string | undefined = undefined) {
			if (process.client) {
				importRunTask().then(({ runTask }) => {
					runTask(() => {
						// No refWatcherKey provided -> clean them all
						if (!watcherKey) {
							Object.keys(this.renderSizing.renderWatchers).forEach((existingRefWatcherKey) => {
								window.cancelAnimationFrame(
									this.renderSizing.renderWatchers[existingRefWatcherKey],
								);
							});

							return;
						}

						// ... otherwise just the one requested if exists
						if (watcherKey in this.renderSizing.renderWatchers) {
							window.cancelAnimationFrame(this.renderSizing.renderWatchers[watcherKey]);
						}
					});
				});
			}
		},
	},
});
