import Vue from 'vue';
import { setSafeTimeout } from '../../assets/js/utilities/timeout';
import { importLogger, importRunTask } from '../../assets/js/utilities/dynamicImports';
import { yieldToMain } from '../../assets/js/utilities/runTask';
import { ClsBaseMixin, IClsConfig } from './cls-base-mixin';

interface ClsRelevantVueComponentOptions {
	_componentTag?: string;
}

export interface IClsOptimizationMixin {
	isMounted: boolean;
	clsMountingCompleted: boolean;
	clsUID: string | boolean;
	clsPreparedComponents: object;
	clsSkipOptimizationRequested: boolean;
	clsStoreOptimizationEnabled: boolean;
	clsComponentKey: string;
	clsManualComponentKey: string;
	clsFallbackTimer: number | null;
	visibilityCheckCount: number;
	visibilityCheckMax: number;
	fallbackActive: boolean;
	clsVisibilityChecker: number;
	resetGlobalClsStatesTrigger: boolean;

	clsInitPreparationStatus(key: string): void;

	clsRegisterPrepared(key: string, savely?: boolean): void;

	clsMountingCompletedCheck(): boolean;

	clsFinalize(): void;

	clsResetStates(): void;

	clsStartFallbackTimer(): void;

	clsStartVisibilityCheck(): void;

	doSkipClsOptimization(): void;

	clsElementIsVisiblyRendered(): boolean;

	cleanup(): void;

	clsUpdateUID(): void;

	clsShowRefElement(refKey: string, withVisibility?: boolean): void;

	clsCheckAllComponentsPrepared(): boolean;

	clsCheckForVisibilityWatcher(): void;

	clsSetPreparedComponent(key: string, state: boolean): void;

	// -- defined in store context
	clsStoreGetConfig(): IClsConfig;

	clsStoreSaveSsrClsState(): void;

	clsStoreClearStates(): void;
}

/**
 * Deprecated. Use ClsComponentMixin and ClsContainerMixin for new implementations instead
 * @deprecated
 */
export const ClsOptimizationMixin = ClsBaseMixin.extend({
	data() {
		return {
			clsUID: false,
			clsManualComponentKey: null,
			clsPreparedComponents: {} as { [key: string]: boolean },
			isMounted: false,
			clsSkipOptimizationRequested: false,
			clsFallbackTimer: null as number | null,
			clsFallbackTimerMs: 0,
			clsElementIsVisiblyRendered: false,
			isVisibilityWatcherRunning: false,
			// @ts-ignore
			clsVisibilityObserver: {} as ResizeObserver,
			clsStoreEmbedded: false,
			clsPreparationFinished: false,
		};
	},
	async fetch() {
		// To prevent flickering - set/get CLS states via store in fetch() depending if SSR or new visitor on client
		if (process?.server) {
			// If is server side rendering - store states of pre-rendered components to prevent flickering
			this.clsStoreSaveSsrClsState();
		} else if (!this.$nuxt.context.from) {
			// If is client side and not coming from within the spa - fetch the ssr cls states
			await importRunTask().then(async ({ runTaskWithPromise }) => {
				await runTaskWithPromise(this.clsStoreGetSsrClsState);
			});
		}
	},
	computed: {
		clsOptimizationEnabled(): boolean {
			// - Is the optimization activated/deactivated by config?
			// - Are we in browser? (no CLS optimization needed for server side rendering)
			// - Was a skip of cls optimization requested? Eg. due to timeouts
			// - Is the cls optimization enabled by store config (servercontext)
			return (
				process?.browser &&
				this.clsData.config.optimizationActive &&
				this.clsStoreOptimizationEnabled &&
				!this.clsSkipOptimizationRequested
			);
		},
		clsComponentKey(): string | boolean {
			this.clsUpdateUID();

			// If component exists just once the name or component tag will be the identifier in the store
			// If component exists multiple times, add a unique id to the key, which has to be defined
			// in the component (e.g. for product tiles listing page)
			return (
				[
					(this.$options as ClsRelevantVueComponentOptions)._componentTag ||
						this.clsManualComponentKey ||
						this.$options?.name,
					this.clsUID,
				]
					.filter(Boolean)
					.join('-') || false
			);
		},
		// to be overwritten in store context
		clsStoreOptimizationEnabled(): boolean {
			return true;
		},
	},
	watch: {
		clsPreparedComponents: {
			deep: true,
			handler(newVal, oldVal): void {
				importRunTask().then(({ runTask }) => {
					runTask(() => {
						if (newVal !== oldVal) {
							// Only allow enhancement of preparedComponentsList, no reducing
							if (Object.keys(newVal).length < Object.keys(oldVal).length) {
								this.clsPreparedComponents = oldVal;
							}
						}

						this.clsCheckForVisibilityWatcher();
					});
				});
			},
		},
		// Used to trigger a reset of ALL cls components, as this is connected to store in shop usage
		_clsGlobalResetStatesTrigger(shouldUpdate): void {
			if (shouldUpdate) {
				this.clsResetStates();
			}
		},
	},
	created() {
		if (process.client) {
			// Start CLS handling
			importRunTask().then(({ runTask }) => {
				runTask(() => {
					this.clsClientInitialization();
				});
			});
		}

		if (
			process.server ||
			!this.clsOptimizationEnabled ||
			(this.$nuxt
				? !this.$nuxt?.context?.from || 'force-device' in this.$nuxt?.context?.from?.query
				: !document.referrer || !document.referrer?.includes(window?.location?.hostname))
		) {
			this.doSkipClsOptimization();
		}
	},
	mounted() {
		try {
			// Flag is used in checks to ensure the component is already mounted
			// and in computed properties it works as a trigger
			this.isMounted = true;

			// check if component was already rendered by ssr
			if (
				typeof (this.$el as HTMLElement)?.getAttribute === 'function' &&
				(this.$el as HTMLElement)?.getAttribute('data-cls-finished') === 'true'
			) {
				this.doSkipClsOptimization();
			}

			// Skip cls optimizations for html comment nodes
			if (this.$el && this.$el.nodeType === 8) {
				this.doSkipClsOptimization();

				return;
			}

			// If optimization was not requested to skip (eg due to timeouts) and all components are already status prepared
			if (!this.clsSkipOptimizationRequested && this.clsCheckAllComponentsPrepared()) {
				this.performVisibilityCheck();

				if (this.clsElementIsVisiblyRendered) {
					// If component is visible rendered, do skip cls optimization functions to prevent flickering
					this.doSkipClsOptimization();
				} else if (Object.keys(this.clsPreparedComponents).length === 0) {
					// If component just has the clsOpitimizationMixin without dependencies on subcomponents, check for its visibility
					this.clsCheckForVisibilityWatcher();
				}
			}
		} finally {
			// If fallback is allowed, start the timer for timeout
			if (this.clsData.config?.allowFallback && !this.clsSkipOptimizationRequested) {
				this.clsFallbackTimerMs = this.clsData.config.fallbackTimerMs;

				this.clsStartFallbackTimer();
			}
		}
	},
	beforeDestroy() {
		this.cleanup();
	},
	methods: {
		clsClientInitialization(): void {
			if (process?.browser) {
				// If is client side ...
				if (this.clsCheckAllComponentsPrepared()) {
					// Is client side, previously was not coming from within spa and all components have status prepared true
					// or no sub components are declared, check if element is visible
					this.checkIfVisible();

					if (this.clsElementIsVisiblyRendered) {
						// If component is visible rendered, do skip cls optimization functions to prevent flickering
						this.doSkipClsOptimization();
					}
				}
			} else {
				// Within SPA navigation, components loading takes a bit longer than for initial calls. So give the ordered
				// loading a bit more time to do its job before fallback is activated
				this.clsFallbackTimerMs =
					this.clsData.config.fallbackTimerMsSpa || this.clsData.config.fallbackTimerMs;

				// ... otherwise clear the cls states if exist
				this.clsStoreClearStates();
			}
		},
		clsSetPreparedComponent(key: string, value: boolean): void {
			if (typeof this.clsPreparedComponents[key] === 'undefined') {
				// If key is not yet present, add it via Vue.set to provide reactivity on this property
				Vue.set(this.clsPreparedComponents, key, value);
			} else {
				this.clsPreparedComponents[key] = value;
			}
		},
		clsGetPreparedComponent(key: string): boolean {
			return this.clsPreparedComponents[key];
		},
		clsInitPreparationStatus(key: string): void {
			this.clsDebug(`${this.clsComponentKey} dynamically adds cls status for ${key}`);

			this.clsSetPreparedComponent(key, !this.clsOptimizationEnabled);
		},
		clsRegisterPrepared(key: string, savely = false): void {
			importRunTask().then(({ runTask }) => {
				runTask(() => {
					this.clsDebug(`${this.clsComponentKey} prepared ${key}`);

					if (savely) {
						if (key in this.clsPreparedComponents && !this.clsGetPreparedComponent(key)) {
							// Only register state when key was already created
							this.clsSetPreparedComponent(key, true);
						}
					} else {
						this.clsSetPreparedComponent(key, true);
					}

					if (this.clsCheckAllComponentsPrepared() && process.client) {
						this.clsStartWatchVisibility();
					}
				});
			});
		},
		clsMountingCompletedCheck(): boolean {
			return (
				!this.clsOptimizationEnabled || (this.isMounted && this.clsCheckAllComponentsPrepared())
			);
		},
		clsCheckAllComponentsPrepared(): boolean {
			return (
				Object.values(this.clsPreparedComponents).length === 0 ||
				!Object.values(this.clsPreparedComponents).includes(false)
			);
		},
		clsStartFallbackTimer(): void {
			// If no fallback timer exists yet
			if (
				process.client &&
				this.clsOptimizationEnabled &&
				this.clsData.config?.allowFallback &&
				!this.clsFallbackTimer
			) {
				// ... create one to skipt cls optimiatzions and activate all cls components
				// after a certain amount of time

				importRunTask().then(({ runTask }) => {
					runTask(() => {
						this.clsFallbackTimer = setSafeTimeout(() => {
							this.clsDebug(`#### TIMEOUT for ${this.clsComponentKey}`);

							if (this.clsData.config?.allowFallback && !this.clsSkipOptimizationRequested) {
								this.doSkipClsOptimization();
							} else {
								this.cleanup();
							}
						}, this.clsFallbackTimerMs);
					});
				});
			}
		},
		clsStartWatchVisibility(): void {
			if (process.server) {
				this.doSkipClsOptimization();
				return;
			}

			this.isVisibilityWatcherRunning = true;
			this.checkIfVisible();
		},
		checkIfVisible(oneTime = false, timeout: number | undefined = undefined) {
			if (!!timeout && Date.now() > timeout) {
				this.doSkipClsOptimization();
			}

			if (
				!this.clsData.config.visibilityCheck ||
				(this.$el && (this.$el.nodeType === 8 || this.$el.innerHTML.trim() === ''))
			) {
				this.clsFinalize();

				return;
			}

			this.performVisibilityCheck();

			this.stopObservingOrRetry(oneTime, timeout);
		},
		stopObservingOrRetry(oneTime: boolean, timeout: number | undefined) {
			if (
				process.server ||
				this.clsElementIsVisiblyRendered ||
				this.clsData.config?.allowFallback
			) {
				this.clsVisibilityObserver?.unobserve?.(this.$el);

				this.clsFinalize();
			} else if (!oneTime) {
				if (!timeout && this.clsData.config?.allowFallback) {
					timeout = Date.now() + this.clsData.config.fallbackTimerMs;
				}

				// @ts-ignore
				this.clsVisibilityObserver = new ResizeObserver((_entries) => {
					this.checkIfVisible(false, timeout);

					if (this.clsVisibilityObserver && this.clsElementIsVisiblyRendered) {
						this.clsVisibilityObserver.disconnect();
					}
				});

				this.clsVisibilityObserver.observe(this.$el);
			}
		},
		performVisibilityCheck(): void {
			this.clsElementIsVisiblyRendered =
				this.isMounted && this.$el && this.$el.clientHeight > 0 && this.$el.clientWidth > 0;
		},
		clsFinalize(): void {
			this.clsPreparationFinished = true;

			this.$emit('clsPreparationFinished', this.clsUID);

			if (this.clsFallbackTimer || this.clsVisibilityObserver) {
				this.cleanup();
			}
		},
		doSkipClsOptimization(): void {
			importRunTask().then(({ runTask }) => {
				runTask(() => {
					this.clsDebug(`${this.clsComponentKey} skips cls optimization`);

					this.clsSkipOptimizationRequested = true;

					this.clsActivateAll();
					this.clsShowRefElement('self', true);
					this.clsFinalize();
				});
			});
		},
		cleanup(): void {
			importRunTask().then(({ runTask }) => {
				runTask(() => {
					this.clsDebug(`cleared fallback timer for ${this.clsComponentKey}`);

					if (this.clsFallbackTimer) {
						clearTimeout(this.clsFallbackTimer);
					}

					if (process.client) {
						this.clsVisibilityObserver?.unobserve?.(this.$el);
					}
				});
			});
		},
		clsResetStates(): void {
			Object.keys(this.clsPreparedComponents).forEach((key) =>
				this.clsSetPreparedComponent(key, false),
			);

			this.clsPreparationFinished = false;

			this.clsSkipOptimizationRequested = false;
		},
		clsActivateAll(): void {
			this.$emit('clsActivateAll');
			this.clsDebug(`Activate all cls sub components of ${this.clsComponentKey}`);

			Object.keys(this.clsPreparedComponents).forEach(async (key) => {
				await yieldToMain();
				this.clsSetPreparedComponent(key, true);
			});
		},
		clsShowRefElement(refKey: string, withVisibility = false): void {
			if (refKey !== 'self' && !(refKey in this.$refs)) return;

			let componentRef;

			if (refKey === 'self') {
				// eslint-disable-next-line @typescript-eslint/no-this-alias
				componentRef = this;
			} else {
				componentRef = Array.isArray(this.$refs[refKey])
					? ((this.$refs[refKey] as (Vue | Element)[])[0] as Vue)
					: (this.$refs[refKey] as Vue);
			}

			if (componentRef && componentRef.$el && componentRef.$el.nodeType !== 8) {
				// show the component
				const htmlElement = componentRef.$el as HTMLElement;

				// Ensure visibility depending on its hidden state
				if (htmlElement.style.display === 'none') {
					htmlElement.style.display = '';
				}

				if (withVisibility) {
					if (htmlElement.style.visibility) {
						htmlElement.style.visibility = 'visible';
					}

					htmlElement.classList.remove('cls-hidden');
				}
			}
		},
		clsCheckForVisibilityWatcher() {
			// If mounting status switches to mounded completed ...
			if (this.isMounted && !this.isVisibilityWatcherRunning) {
				importRunTask().then(({ runTask }) => {
					runTask(() => {
						if (this.clsMountingCompletedCheck()) {
							// ... start visibility check / watcher
							this.clsStartWatchVisibility();
						}
					});
				});
			}
		},
		clsDebug(message: string): void {
			if (!this.clsData?.config?.debugging?.active) return;

			importLogger().then(({ default: Logger }) => {
				Logger.debug(`[CLS] ${message}`);
			});
		},
		// to be overwritten in store context
		clsStoreGetConfig(): IClsConfig | undefined {
			return (process.client && (window as any).appState?.cls?.config) || undefined;
		},
		clsUpdateUID(): void {
			// to be overwritten in using component
		},
		// to be overwritten in store context
		clsStoreSaveSsrClsState(): void {},
		// to be overwritten in store context
		clsStoreGetSsrClsState(): void {},
		// to be overwritten in store context
		clsStoreClearStates(): void {},
	},
});
