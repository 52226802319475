import Vue from 'vue';
import { IRenderSizingMixin, RenderSizingMixin } from '../../components/mixins/render-sizing-mixin';
import { importRunTask, importTimeout } from '../../assets/js/utilities/dynamicImports';
import { runTask } from '../../assets/js/utilities/runTask';

export enum ClsEventType {
	register,
	updated,
	finished,
}

export interface IClsEventObject {
	type: ClsEventType;
	key: string;
	value: any;
}

export interface DebugSettings {
	active: boolean;
}

export interface IClsConfig {
	optimizationActive: boolean;
	allowFallback: boolean;
	fallbackTimerMs: number;
	fallbackTimerMsSpa: number;
	visibilityCheck: boolean;
	debugging: DebugSettings;
}

export interface IClsBaseMixin {
	clsData: {
		key: string | undefined;
		subComponentsVisibility: { [key: string]: boolean };
		config: IClsConfig;
		allSubComponentsVisible: boolean;
	};

	emitClsEvent: (type: ClsEventType) => void;
	clsReset: () => void;
	_checkAllSubcomponentsFinished: () => void;
	// --- legacy data items start ---
	clsUID: boolean | string;
	clsPreparationFinished: boolean;
}

export const ClsBaseMixin = Vue.extend({
	mixins: [RenderSizingMixin],
	data() {
		return {
			// @ts-ignore
			clsData: this['clsData'] /* eslint-disable-line dot-notation */ ?? {
				config: {
					optimizationActive: true,
					allowFallback: true,
					fallbackTimerMs: 1500,
					fallbackTimerMsSpa: 2500,
					visibilityCheck: true,
					debugging: {
						active: false,
						outputTo: 'console',
					},
				} as IClsConfig,
				key: (this as any).cls?.key || (undefined as string | undefined),
				condition: (this as any).cls?.condition || (undefined as undefined | boolean),
				mounted: false,
				preRendered: undefined as undefined | boolean,
				timeoutReached: false,
				allSubComponentsVisible: false,
				growingStopped: false,
				subComponentsVisibility: {} as { [key: string]: boolean },
				timeout: 0,
				_fallbackTimer: null as null | number,
				isRegistered: process.server,
				isFinished: process.server,
			},
			// --- legacy data items start ---
			clsUID: false as boolean | string,
			clsPreparationFinished: false,
		};
	},
	computed: {
		clsControlShouldHappen() {
			if (process.client && this.clsData.config.optimizationActive) {
				// Check if user recently interacted (max 500ms ago). If so, we can skip the cls optimization
				return !(window as any).osp?.userInteraction?.interactedRecently ?? true;
			}

			return this.clsData.config.optimizationActive;
		},
		// Display component if ...
		// - is in server side rendering process (process.server)
		// - no optimization should take place (deactivated by config)
		// - no condition was defined
		// - element was not yet mounted but condition is already true
		// - element was already preRendered and is displayed already (SSR)
		// - a timeout was reached (timeout for cls ordered loading or timeout to check if size change of this element stopped)
		// - EVERYTHING WENT RIGHT: condition from outside to display is met and all registered subcomponents are in state true
		//   and size change of this element stopped
		// - Legacy: clsPreparationFinished from old cls-optimization-plugin
		clsDisplayComponent(): boolean {
			// First check for very easy conditions / flags
			if (
				process.server ||
				!this.clsControlShouldHappen ||
				this.clsData.preRendered === true ||
				this.clsData.timeoutReached
			) {
				return true;
			}

			// No condition given, but ready for display
			if (
				typeof this.clsData.condition === 'undefined' &&
				(this.clsData.growingStopped || this.clsPreparationFinished)
			) {
				return true;
			}

			// Condition is given and met, component is also ready prepared
			if (
				this.clsData.condition &&
				this.clsData.allSubComponentsVisible &&
				(this.clsData.growingStopped || this.clsPreparationFinished)
			) {
				return true;
			}

			if (this.clsData.condition && !this.clsData.mounted) {
				return true;
			}

			// Otherwise - do not display component
			return false;
		},
		// Trigger used to cause a global reset of ALL cls components (when store is available)
		_clsGlobalResetStatesTrigger(): boolean {
			return !!this.$store?.state?.cls?.resetStateAllTrigger;
		},
	},
	beforeMount() {
		if (!this.clsControlShouldHappen) return;

		this._updateClsConfig();
		this._setTimeoutValue();

		if (this.clsData.condition) {
			this._checkAllSubcomponentsFinished();
		}
	},
	mounted() {
		this.clsData.mounted = true;

		runTask(() => {
			if (!this.clsControlShouldHappen) {
				this._onClsDisplayComponentWatch(true, undefined);
				return;
			}

			this._addWatchers();

			if (!this.clsDisplayComponent) {
				this._clsStartProgress();
			}

			this._clsListenForReRegistering();
		});
	},
	beforeDestroy() {
		this._clsCleanup();
	},
	methods: {
		_addWatchers() {
			this.$watch('clsDisplayComponent', this._onClsDisplayComponentWatch, { immediate: true });

			this.$watch('_clsGlobalResetStatesTrigger', (shouldUpdate: boolean) => {
				if (shouldUpdate) {
					this.clsReset();
				}
			});
		},
		_onClsDisplayComponentWatch(newValue: boolean, oldValue: boolean | undefined) {
			runTask(() => {
				// If display condition changes - toggle visibility
				if (newValue) {
					this._clsStopFallbackTimer();
					this._clsActivateAllSubcomponents();

					// Toggle visibility by cls condition
					this.$el?.classList?.remove('cls-hidden');

					// If oldValue was undefined, it was the initial check and cls control has finished initial setup
					if (typeof oldValue === 'undefined') {
						this.clsData.isFinished = true;
						this.emitClsEvent(ClsEventType.finished);
					}
				} else {
					this.$el?.classList?.add('cls-hidden');

					if (newValue === false && oldValue === true) {
						// If condition changes from true to false => reset and reactivate the fallback timer again
						this.emitClsEvent(ClsEventType.updated);
						this._clsReactivateFallbackTimer();
					}
				}
			});
		},
		_clsListenForReRegistering() {
			this.$root.$on('reRegisterClsComponents', () => {
				if (!this.clsControlShouldHappen) return;

				this._clsEmitRegister();
			});
		},
		emitClsEvent(clsEventType: ClsEventType): void {
			// Avoid multiple registration
			if (clsEventType === ClsEventType.register && this.clsData.isRegistered) return;

			// Ensure registration, in case already receiving clsEvent while registration not yet happened
			if (!this.clsData.isRegistered && clsEventType !== ClsEventType.register) {
				this._clsEmitRegister();
			}

			this.$emit('clsEvent', {
				type: clsEventType,
				key: this._getClsKey(),
				value: this.clsDisplayComponent,
			} as IClsEventObject);

			// Avoid multiple registration
			this.clsData.isRegistered = true;

			// --- Support for legacy events
			if (clsEventType === ClsEventType.finished) {
				this._clsEmitLegacyEvents();
			}
		},
		clsReset(): void {
			setTimeout(() => {
				Object.keys(this.clsData.subComponentsVisibility).forEach((key) => {
					this.clsData.subComponentsVisibility[key] = false;
				});

				this.clsData.preRendered = false;
				this.clsData.timeoutReached = false;
				this.clsData.allSubComponentsVisible = false;
				this.clsData.growingStopped = false;
			});
		},
		clsRestart(): void {
			this.clsReset();
			this._clsStartProgress();
		},
		_clsStartProgress(): void {
			if (!this.clsControlShouldHappen) return;

			this._checkAllSubcomponentsFinished();

			if (!this.clsData.preRendered) {
				this._clsStartFallbackTimer();
			}
		},
		_updateClsConfig() {
			importRunTask().then(({ runTask }) => {
				runTask(() => {
					// If cls config is available in store state, replace the initial one
					if (this.$store?.state?.cls?.config) {
						this.clsData.config = this.$store.state.cls.config;
					}

					// If cls optimization enabling is defined in session server context, update config
					if (this.$store?.state?.servercontext?.session?.clsOptimizationEnabled) {
						this.clsData.config.optimizationActive =
							this.$store.state.servercontext.session.clsOptimizationEnabled;
					}
				});
			});
		},
		_getClsKey() {
			return (
				this.clsData?.key ||
				(this.$vnode?.componentOptions?.tag?.indexOf('Lazy') === 0
					? this.$vnode?.componentOptions?.tag?.replace('Lazy', '')
					: this.$vnode?.componentOptions?.tag)
			);
		},
		_checkAllSubcomponentsFinished() {
			const subComponentStatuses = Object.values(this.clsData.subComponentsVisibility);

			this.clsData.allSubComponentsVisible =
				subComponentStatuses.length === 0 || !subComponentStatuses.includes(false);

			if (this.clsData.allSubComponentsVisible) {
				this._checkGrowingStopped();
			}
		},
		_checkGrowingStopped() {
			(this as unknown as IRenderSizingMixin).watchRenderSizing({
				refKey: 'self',
				watcherKey: 'clsGrowing',
				callback: () => {
					this.clsData.growingStopped = true;
				},
				failCallback: () => {
					this.clsData.timeoutReached = true;
				},
				timeout: this.clsData.config.fallbackTimerMs,
			});
		},
		_clsStartFallbackTimer(callbackOnFallback: undefined | (() => void) = undefined) {
			// If on client and fallback allowed and no fallback timer running yet, start fallback timeout
			if (process.client && this.clsData?.config?.allowFallback && !this.clsData?._fallbackTimer) {
				importRunTask().then(({ runTask }) => {
					runTask(() => {
						importTimeout().then(({ setSafeTimeout }) => {
							this.clsData._fallbackTimer = setSafeTimeout(() => {
								this.clsData.timeoutReached = true;

								this._clsCleanup();

								if (callbackOnFallback) {
									callbackOnFallback();
								}
							}, this.clsData.timeout);
						});
					});
				});
			}
		},
		_clsStopFallbackTimer() {
			if (this.clsData._fallbackTimer && window) {
				window.clearTimeout(this.clsData._fallbackTimer);
			}
		},
		_clsReactivateFallbackTimer() {
			this.clsData.timeoutReached = false;
			this.clsData._fallbackTimer = null;
			this._clsStartFallbackTimer();
		},
		_clsCleanup() {
			this._clsStopFallbackTimer();
		},
		_setTimeoutValue() {
			// Update timeout depending on if component gets rendered from navigation within SPA or access from outside
			this.clsData.timeout = !this.$nuxt?.context?.from
				? this.clsData.config.fallbackTimerMs
				: this.clsData.config.fallbackTimerMsSpa;
		},
		_clsActivateAllSubcomponents() {
			Object.keys(this.clsData.subComponentsVisibility).forEach((key) => {
				if (!this.clsData.subComponentsVisibility[key]) {
					this.clsData.subComponentsVisibility[key] = true;
				}
			});
			this.$emit('clsActivateAll');
		},
		_clsEmitRegister() {
			this.$emit('clsEvent', {
				type: ClsEventType.register,
				key: this._getClsKey(),
				value: this.clsDisplayComponent,
			} as IClsEventObject);

			this.clsData.isRegistered = true;
		},
		_clsEmitLegacyEvents() {
			const idToEmit = this.clsUID || this._getClsKey();

			this.$emit('clsPreparationFinished', idToEmit);
			this.$emit('done', idToEmit);
		},
	},
});
